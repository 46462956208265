/*
	App routes files
*/
import React, { Suspense, lazy } from 'react';
import { 	Route,
			Switch, 
			Redirect, 
			BrowserRouter as Router } from 'react-router-dom'

// pages

// lazy load components
const Yoga = React.lazy(() => import('./pages/Yoga/lp'));
// const ThankYou = React.lazy(() => import('./pages/Yoga/ThankYou'));
const ThinkPad = React.lazy(() => import('./pages/ThinkPad/lp'));



// Posts
// import Posts from '../Posts/Posts/Posts';

/*
 * Defing client route
 * 
*/
export const Routes = () => {
	
	return(
		<Router>
		  	<Suspense fallback={<div>Loading...</div>}>
				<Switch>
					<Route path="/ThinkPad/" component={ThinkPad} />
					<Route path="/Yoga/" component={Yoga} />
					<Route path="/"  component={Yoga} />
				</Switch>
		 	</Suspense>
		</Router>

	)
}
